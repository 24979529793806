import * as _whatwgMimetype2 from "whatwg-mimetype";
var _whatwgMimetype = _whatwgMimetype2;
try {
  if ("default" in _whatwgMimetype2) _whatwgMimetype = _whatwgMimetype2.default;
} catch (e) {}
import * as _whatwgUrl2 from "whatwg-url";
var _whatwgUrl = _whatwgUrl2;
try {
  if ("default" in _whatwgUrl2) _whatwgUrl = _whatwgUrl2.default;
} catch (e) {}
import _utils from "./utils.js";
var exports = {};
const MIMEType = _whatwgMimetype;
const {
  parseURL,
  serializeURL,
  percentDecodeString
} = _whatwgUrl;
const {
  stripLeadingAndTrailingASCIIWhitespace,
  isomorphicDecode,
  forgivingBase64Decode
} = _utils;
exports = stringInput => {
  const urlRecord = parseURL(stringInput);
  if (urlRecord === null) {
    return null;
  }
  return exports.fromURLRecord(urlRecord);
};
exports.fromURLRecord = urlRecord => {
  if (urlRecord.scheme !== "data") {
    return null;
  }
  const input = serializeURL(urlRecord, true).substring("data:".length);
  let position = 0;
  let mimeType = "";
  while (position < input.length && input[position] !== ",") {
    mimeType += input[position];
    ++position;
  }
  mimeType = stripLeadingAndTrailingASCIIWhitespace(mimeType);
  if (position === input.length) {
    return null;
  }
  ++position;
  const encodedBody = input.substring(position);
  let body = percentDecodeString(encodedBody);

  // Can't use /i regexp flag because it isn't restricted to ASCII.
  const mimeTypeBase64MatchResult = /(.*); *[Bb][Aa][Ss][Ee]64$/u.exec(mimeType);
  if (mimeTypeBase64MatchResult) {
    const stringBody = isomorphicDecode(body);
    body = forgivingBase64Decode(stringBody);
    if (body === null) {
      return null;
    }
    mimeType = mimeTypeBase64MatchResult[1];
  }
  if (mimeType.startsWith(";")) {
    mimeType = `text/plain${mimeType}`;
  }
  let mimeTypeRecord;
  try {
    mimeTypeRecord = new MIMEType(mimeType);
  } catch (e) {
    mimeTypeRecord = new MIMEType("text/plain;charset=US-ASCII");
  }
  return {
    mimeType: mimeTypeRecord,
    body
  };
};
export default exports;
export const fromURLRecord = exports.fromURLRecord;